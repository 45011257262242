import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useDeeplinkOffer = () => {
  const location = useLocation();
  const productFamilyIntent = new URLSearchParams(location.search).get('switchingTo');

  const allAvailableOffers = useSelector(state => state.offers ?? { data: {} }, shallowEqual);
  const productFamilyOffers = allAvailableOffers[productFamilyIntent];
  const offerId = productFamilyOffers?.trialId ?? productFamilyOffers?.offerId;

  return offerId ? Object.values(allAvailableOffers.data).find(offer => offer.id === offerId) : null;
};

export default useDeeplinkOffer;
