import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import MobileSwitch from './switch';
import { splitsList } from '../../constants';
import getCustomer from '../../actions/auth/getCustomer';
import getAllOffers from '../../actions/offers/getAll';
import getAllSubscriptions from '../../actions/subscriptions/getAll';
import { getSplitStatus } from '../../reducers/selectors';
import getScore from '../../actions/scores/getScore';

const CORE_DEPENDENCIES = [getCustomer, getAllOffers, getScore, getAllSubscriptions];

// A home for Webview routes - will be responsible for anything that needs to be available to all routes
const MobileRoutes = () => {
  const [initialised, setInitialised] = useState(false);

  const mobileSwitchEnabled = useSelector(
    state => getSplitStatus(state.split, [splitsList.billingMigration])[splitsList.billingMigration]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const initialise = async () => {
      await Promise.allSettled(CORE_DEPENDENCIES.map(dependency => dispatch(dependency())));
      setInitialised(true);
    };

    initialise();
  }, [dispatch]);

  if (!initialised) {
    // a user is seeing a native spinner
    return null;
  }

  // core deps available, each route will be responsible for telling the app that it's loaded
  return <Route exact path="/mobile/switch" component={mobileSwitchEnabled ? MobileSwitch : null} />;
};

export default MobileRoutes;
