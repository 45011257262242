import fetch from '../fetch';
import abTests from '../../constants/abtests';
import getCookieValue from '../../helpers/cookies/getCookieValue';
import { isFreeTrial } from '../../helpers/subscription';

export const FETCH_SELECTIONS = 'FETCH_SELECTIONS';
export const SELECTIONS_FETCHED = 'SELECTIONS_FETCHED';
export const SELECTIONS_FAILED = 'SELECTIONS_FAILED';
export const SELECTIONS_UNAVAILABLE = 'SELECTIONS_UNAVAILABLE';

const fetchTargetingVariants = async (dispatch, parameters) =>
  dispatch(
    fetch(
      '/targeting',
      {
        method: 'POST',
        body: {
          application: 'prodmove',
          testIds: Object.values(parameters.abTests).map(test => test.apiCode),
          parameters: {
            inputs: parameters.inputs,
            traits: parameters.traits,
          },
        },
      },
      { isRemote: false }
    )
  );

const getSelections = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_SELECTIONS });

  const state = getState();
  const fetched = {};

  const privacyCookies = getCookieValue('EXPERIAN_ENSIGHTEN_PRIVACY_Performance_and_Analytics') === '1';
  if (!privacyCookies) {
    fetched.type = SELECTIONS_UNAVAILABLE;
    return dispatch(fetched);
  }

  const parameters = {
    abTests,
    traits: {
      eligibleForTrial: isFreeTrial(
        state.offers?.data?.[state.offers?.credit_expert?.trialId ?? state.offers?.credit_expert?.offerId]
          ?.descriptors ?? []
      ),
      family: state.subscriptions?.current?.family,
      ...(state.creditLock?.isLocked !== null && { isLockStatusOn: state.creditLock?.isLocked }),
    },
    inputs: {
      customerNumber: state.auth?.currentUser?.customerNumber,
    },
  };

  try {
    const targetingData = await fetchTargetingVariants(dispatch, parameters);
    fetched.payload = {
      data: targetingData
        ?.find(entry => entry.context === 'Targeting')
        ?.data?.reduce((acc, curr) => ({ ...acc, [curr.variantId]: curr.variantValue }), {}),
    };
    fetched.type = SELECTIONS_FETCHED;
  } catch (error) {
    fetched.type = SELECTIONS_FAILED;
  }

  return dispatch(fetched);
};

export default getSelections;
