import { useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Analytics from '@experian-uk/web-common-analytics';

import getCommonECDData from '../reducers/selectors/getCommonECDData';

const initialiseWithMetadata = async (pageName, commonMetadata, additionalMetadataPromise) => {
  const metadata = await additionalMetadataPromise;
  return Analytics.init({ ...metadata, ...commonMetadata }, pageName);
};

const PAGE_VIEW_STAGES = {
  NOT_INITIALISED: 0,
  IN_PROGRESS: 1,
  INITIALISED: 2,
};

const usePageView = (pageName, additionalMetadataPromise) => {
  const { NOT_INITIALISED, IN_PROGRESS, INITIALISED } = PAGE_VIEW_STAGES;

  const pageViewInitialised = useRef(NOT_INITIALISED);
  const commonMetadata = useSelector(state => getCommonECDData(state, { requireSelections: false }), shallowEqual);

  if (pageViewInitialised.current === NOT_INITIALISED) {
    pageViewInitialised.current = IN_PROGRESS;
    initialiseWithMetadata(pageName, commonMetadata, additionalMetadataPromise)
      .then(() => {
        pageViewInitialised.current = INITIALISED;
      })
      .catch(() => {
        pageViewInitialised.current = NOT_INITIALISED;
      });
  }
};

export default usePageView;
