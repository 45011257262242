import React from 'react';

/*
    We're planning to incrementally import (and improve within reason) aspects of the existing Switch page as we build out the new provider flow from this page.
    This approach has been chosen to minimise the amount of branching we need to do within an already complex switch page. This does increase risk of keeping changes
    there in sync - but it has been asked that work in the repository is kept to a minimum.

    Regression testing is planned for all product movement journeys as they are supported here.
*/
const WebSwitchPage = () => <h1>Switch Migration - Web</h1>;

export default WebSwitchPage;
