import dayjs from 'dayjs';

import eligibleForCeTrial from '../../actions/ecd/resolvers/eligibleForCeTrial';

const defaultConfiguration = {
  requireSelections: true,
};

export default (state, config = defaultConfiguration) => {
  if (!state.auth.currentUser || (config.requireSelections && !state.conductrics.sels)) {
    return null;
  }
  return {
    customer: {
      customerNumber: state.auth.currentUser.customerNumber,
      transformationBetaStatus: state.conductrics.sels?.redesignBetaTest,
      authenticationStatus: true,
      eligibleForTrial: eligibleForCeTrial(state),
      previousScore: state.scores?.data?.[1]?.score ?? null,
      score: state.scores?.data?.[0]?.score ?? null,
      startDate: dayjs(state.auth.currentUser.createDt).format('MM/DD/YYYY'),
      isoStartDate: dayjs(state.auth.currentUser.createDt).toISOString(),
      subscriptionType: state.subscriptions?.current?.family,
      boostUplift: state.scores?.data?.[0]?.uplift ?? null,
    },
  };
};
