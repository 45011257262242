import { products } from '../../constants';

const VALID_MOVEMENTS = {
  [products.basic]: [products.identity, products.credit_expert, products.breach, products.breachPlus],
  [products.identity]: [products.basic, products.credit_expert],
  [products.credit_expert]: [products.basic, products.identity],
  [products.breach]: [products.breachPlus, products.identity, products.credit_expert],
  [products.breachPlus]: [products.basic, products.breach, products.identity, products.credit_expert],
};

const isValidSwitch = (currentOffer, targetOffer) => {
  const currentFamily = currentOffer?.family;
  const targetFamily = targetOffer?.family;

  if (!currentFamily || !targetFamily) {
    return false;
  }

  const validTargetFamilies = VALID_MOVEMENTS[currentFamily];

  return validTargetFamilies.includes(targetFamily);
};

export default isValidSwitch;
