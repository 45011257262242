import React, { useEffect } from 'react';
import { sendMobileErrorNotification, sendMobileLoadedNotification } from '@experian-uk/web-common-mobile-view-helpers';

import isValidSwitch from '../../../helpers/switch/isValidSwitch';
import usePageView from '../../../hooks/usePageView';
import useDeeplinkOffer from '../../../hooks/useDeeplinkOffer';
import useCurrentOffer from '../../../hooks/useCurrentOffer';

const PAGE_NAME = 'ProdMoveSwitchPageMobile';

const MobileSwitchPage = () => {
  usePageView(PAGE_NAME);

  const currentOffer = useCurrentOffer();
  const switchTarget = useDeeplinkOffer();

  const validSwitch = isValidSwitch(currentOffer, switchTarget);

  useEffect(() => {
    if (switchTarget && validSwitch) {
      sendMobileLoadedNotification();
    } else {
      sendMobileErrorNotification();
    }
  }, [switchTarget, validSwitch]);

  if (!switchTarget || !validSwitch) {
    return null;
  }

  return (
    <div>
      <h1>Switch Migration - Webview</h1>
      <table>
        <tbody>
          <tr>
            <td>Selected Product Family</td>
            <td>{switchTarget.family}</td>
          </tr>
          <tr>
            <td>Selected Offer</td>
            <td>
              {switchTarget.upc} - {switchTarget.name}
            </td>
          </tr>
          <tr>
            <td>Selected Offer ID</td>
            <td>{switchTarget.id}</td>
          </tr>
          <tr>
            <td>Eligible for Trial</td>
            <td>{String(switchTarget.descriptors.includes('free_trial'))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MobileSwitchPage;
