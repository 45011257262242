import { SplitFactory } from '@splitsoftware/splitio';
import Cookies from 'js-cookie';

import { getEnv } from '../components/Context/env';
import { SPLIT_CLIENT_FAILED, SPLIT_CLIENT_READY, SPLITS_FETCHED } from '../actions/split';
import isServer from '../helpers/isServer';
import mockSplit from '../helpers/test/mocks/split';
import { splitsList } from '../constants';

const treatments = Object.values(splitsList);

let splitInitialised = false;

export default (runningOnServer, env = getEnv(), defaultSplitApiKey = 'localhost') =>
  store =>
  next =>
  action => {
    const { split } = store.getState();

    if (!split.key || split.ready || split.error || splitInitialised) {
      return next(action);
    }

    const onServer = [true, false].includes(runningOnServer) ? runningOnServer : isServer();

    splitInitialised = [true, false].includes(split.initialised) ? split.initialised : true;

    const shouldUseLocalMode = onServer || env.REACT_APP_ENV === 'local' || window?.Cypress?.isIntegrationTest;
    const apiKey = shouldUseLocalMode ? defaultSplitApiKey : env.REACT_APP_SPLIT_API_KEY;

    const factory = SplitFactory({
      core: {
        authorizationKey: apiKey || defaultSplitApiKey,
        key: split.key,
      },
      features: mockSplit(split.key),
      startup: {
        readyTimeout: 1.5,
      },
    });

    const client = factory.client();

    client.on(client.Event.SDK_READY, () => {
      store.dispatch({
        type: SPLIT_CLIENT_READY,
      });

      const optimConsent = Cookies.get('EXPERIAN_ENSIGHTEN_PRIVACY_Performance_and_Analytics') === '1';

      try {
        store.dispatch({
          type: SPLITS_FETCHED,
          payload: client.getTreatmentsWithConfig(treatments, { customerNumber: split.key, optimConsent }) || {},
        });
      } catch (e) {
        store.dispatch({
          type: SPLITS_FETCHED,
          payload: e,
        });
      }
    });

    client.on(client.Event.SDK_READY_TIMED_OUT, () => {
      store.dispatch({
        type: SPLIT_CLIENT_FAILED,
      });
    });

    return next(action);
  };
